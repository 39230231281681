var columns = [{
  title: "交易ID",
  dataIndex: "tradeid",
  key: "tradeid",
  width: "6%",
  scopedSlots: {
    customRender: "tradeid"
  }
}, {
  title: "交易用户昵称",
  dataIndex: "tradernickname",
  key: "tradernickname",
  width: 150,
  scopedSlots: {
    customRender: "tradernickname"
  }
}, {
  title: "交易Hash",
  dataIndex: "transactionhash",
  width: 150,
  key: "transactionhash",
  scopedSlots: {
    customRender: "transactionhash"
  }
}, {
  title: "交易金额（ETH）",
  dataIndex: "amount",
  key: "amount",
  width: 200,
  scopedSlots: {
    customRender: "amount"
  }
}, {
  title: "返佣对象用户",
  dataIndex: "rebatename",
  key: "rebatename",
  width: 150,
  scopedSlots: {
    customRender: "rebatename"
  }
}, {
  title: "返佣比例（%）",
  dataIndex: "rebaterate",
  key: "rebaterate",
  width: "10%",
  scopedSlots: {
    customRender: "rebaterate"
  }
}, {
  title: "返佣对象团长",
  dataIndex: "groupleadername",
  key: "groupleadername",
  width: 150,
  scopedSlots: {
    customRender: "groupleadername"
  }
}, {
  title: "返佣团长比例（%）",
  dataIndex: "groupleaderrebaterate",
  key: "groupleaderrebaterate",
  width: "15%",
  scopedSlots: {
    customRender: "groupleaderrebaterate"
  }
}, {
  title: "交易完成时间",
  dataIndex: "tradetime",
  key: "tradetime",
  width: "15%",
  scopedSlots: {
    customRender: "tradetime"
  }
}, {
  title: "操作",
  key: "action",
  dataIndex: "action",
  fixed: "right",
  width: "",
  scopedSlots: {
    customRender: "action"
  }
}];
export { columns };