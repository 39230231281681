var searchKeys = [{
  key: "dates",
  label: "交易时间",
  format: "YYYY-MM-DD HH:mm:ss",
  placeholder: ["开始时间", "结束时间"],
  dates: true,
  required: false,
  rules: [{
    required: false
  }]
}, {
  key: "traderuid",
  label: "交易用户ID",
  placeholder: "交易用户ID",
  required: false,
  rules: [],
  input: true
}, {
  key: "tradernickname",
  label: "交易用户昵称",
  placeholder: "交易用户昵称",
  required: false,
  rules: [],
  input: true
}, {
  key: "transactionhash",
  label: "交易Hash",
  placeholder: "交易Hash",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };